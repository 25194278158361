
import { 
	IonApp, 
	IonRouterOutlet, 
	IonModal,
	IonLoading
} from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { RootStore } from "./store";
import AuthInComponent from '@/components/AuthInComponent.vue'
import YouTubeVideoPlayer from '@/components/YouTubeVideoPlayer.vue';
import useI18nAffidaty from "./composables/I18nAffidatyApi";

export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
		AuthInComponent,
		YouTubeVideoPlayer,
		IonLoading
	},
	setup() {
		const store = useStore<RootStore>()
		const { t } = useI18nAffidaty()
		// video player vars
		const showVideoPlayer = computed<{url: string; title: string}>(() => store.getters.showVideoPlayer)
		const isLoading = computed(() => store.getters["ui/isLoading"])
		const authIn = ref();
		const authReady = (target: any) => {
			store.commit("setAuthInRef", authIn.value? authIn.value : target)
		}

		const hideVideo = () => {
			store.commit("hideVideoPlayer")
		}

		// desktop background management
		if(document.querySelector("body")){
			const brandDesktopBackgroud = computed(() => store.getters['images/brandDesktopBackgroud'])
			const errorbrandDesktopBackgroud = computed(() => store.getters['images/errorDesktopBackgroud'])
			document.querySelector("body")!.style.backgroundImage = `url(${brandDesktopBackgroud.value}), url(${errorbrandDesktopBackgroud.value})`
			document.querySelector("body")!.style.backgroundSize = "cover"
			document.querySelector("body")!.style.backgroundPosition = "center"
		}

		return {
			authinApplication: process.env.VUE_APP_AUTHIN_APPLICATION,
			authinApiendpoint: process.env.VUE_APP_AUTHIN_APIENDPOINT,
			authinApikey: process.env.VUE_APP_AUTHIN_APIKEY,
			env: process.env.VUE_APP_ENVIRONMENT,
			authReady,
			authIn,
			user: computed(() => store.getters.userInformations),
			language: computed(() => store.getters['ui/lang']),
			showVideoPlayer,
			hideVideo,
			isLoading,
			t
		}
	}
});
