import axios from "axios";

export default class HttpRest {
    static get(api: string) {
        return axios.get(`${process.env.VUE_APP_API_ENDPOINT}${api}`)
    }

    static post(api: string, data: any, header?: any) {
        return axios.post(`${process.env.VUE_APP_API_ENDPOINT}${api}`, data, header);
    }
}