<template>
  <div class="ion-padding-horizontal">
    <ion-text color="dark">
      <p>{{text}}</p>
    </ion-text>
  </div>
</template>


<script lang="ts">
import {
  IonText
} from "@ionic/vue";
import { defineComponent, toRefs } from "vue";
const textContainerProps = {
  text: {
    type: String,
    default: ""
  }
}
export default defineComponent({
	components: {
    IonText
	},
  props: textContainerProps,
  setup(props) {
    const { text } = toRefs(props)
    return {
      text
    }
  }
});
</script>

<style scoped>

</style>
