
import {
	IonContent,
	IonPage,
	IonRow,
	IonCol,
	IonSlides,
	IonSlide,
	IonGrid,
	IonCard,
	IonCardContent,
	IonItem,
	IonButton,
	IonIcon,
	IonModal,
} from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { informationCircleOutline, ellipse, ellipseOutline, checkmarkCircle, shieldCheckmark, eyeOff, informationCircle } from "ionicons/icons";
import Modal from "@/components/modals/index.vue";
import SlideMenuComponent from "@/components/SlideMenuComponent.vue";
import ProgressBarComponent from "@/components/layout/ProgressBarComponent.vue";
import Header from '@/components/layout/Header.vue'
import useModal from "@/composables/ModalApi";
import { useRouter } from "vue-router";
import { Ballot, Answer, Question } from "@/models/types";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { isValidAnswer } from '@/utils/utils'

export default defineComponent({
	name: "question-list",
	components: {
		IonContent,
		IonPage,
		IonSlides,
		IonSlide,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonCardContent,
		IonItem,
		IonButton,
		IonIcon,
		Modal,
		IonModal,
		SlideMenuComponent,
		ProgressBarComponent,
		Header
	},
	props: {},
	methods: {
		// Check if current question has response
		questionHasResponse(id: string) {
			return !!this.currentVote.find((answer: any) => {
				return answer.id === id;
			});
		}
	},

	setup() {
		const store = useStore()
		const { t, getLangWithoutRegion } = useI18nAffidaty()
		const {scopedModalInfo, openModal, handleModalClosed, handleSubmitModal} = useModal("scoped")
		const router = useRouter()
		const currentBallot = computed<Ballot>(() => store.getters.currentBallot )
		const currentVote = computed<Answer[]>(() => store.getters.currentVote)
		const sliderRef = ref()
		const MODALTYPE = {
			anonymous: "anonymous",
			ballotInformation: "ballotInformation",
			question: "question",
			language: "language",
		}
		const infoModal = t("The data inserted will not be subject to any treatment, nor stored by E-vote.io, and will be used only for the vote submission")

		// Check if all question has response, can be empty answers.
		const questionHasFullResponse = () => {
			const questionsCount = currentBallot.value.questions.length;
			return questionsCount === currentVote.value.length;
		}

		const onBeforeOpenModal = (question: Question) => {
			const vote = currentVote.value.find((answer: any) => answer.id === question.id)
			openModal(MODALTYPE.question, question.question, {question, answers: (vote? vote.values : [])}, infoModal)
		}

		const questionAction = () => {
			const slider: any = sliderRef.value;
			if (questionHasFullResponse()) {
				//if (await slider.$el.isEnd()) {
				// Navigate to resume
				router.replace({ name: "vote-confirm" });
			} else {
				slider.$el.slideNext();
			}
		}

		const slideToIndex = (index: number) => {
			sliderRef.value.$el.slideTo(index)
		}

		const submitModal = () => {
			const callback = () => {
				const type = scopedModalInfo.type;

				switch (type) {
					case "language":
						//console.log("Language selected -> ", eventData.data);
						// Change language
						break;
					case "question":
						// Mi salvo il voto nel vettore
						questionAction();
						break;
				}
			}
			handleSubmitModal(callback)
		}

		// Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
		const slideOpts = {
			slidesPerView: 1.3,
			initialSlide: 0,
			speed: 400,
			centeredSlides: true,
			pagination: false,
		};

		return {
			icons: { 
				informationCircleOutline, 
				ellipse, 
				ellipseOutline, 
				checkmarkCircle, 
				shieldCheckmark, 
				eyeOff,
				informationCircle
			},
			infoModal,
			slideOpts,
			modalInfo: scopedModalInfo,
			openModal,
			handleModalClosed,
			currentBallot,
			currentVote,
			submitModal,
			questionAction,
			questionHasFullResponse,
			sliderRef,
			t,
			lang: getLangWithoutRegion(),
			slideToIndex,
			onBeforeOpenModal,
			isValidAnswer
		};
	},
});
