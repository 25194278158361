
import { IonFabButton } from "@ionic/vue";
import { computed, defineComponent, toRefs } from "vue";
import { useStore } from "vuex";

const slideMenuProps = {
	questions: {
		type: Object,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	isValidAnswer: {
		type: Boolean,
		required: true,
		default: true
	}
}
export default defineComponent({
	components: {
		IonFabButton,
	},
	name: "SlideMenuComponent",
	emit: ["onSelectQuestionNumber"],
	props: slideMenuProps,
	setup(props, {emit}) {
		const { questions, index, isValidAnswer } = toRefs(props)
		const content =  ""
		const store = useStore()
		const currentVote = computed(() => store.getters.currentVote)
		
		const selectIndex = (selectedIdx: number) => {
			if (index && selectedIdx != index.value){
				emit("onSelectQuestionNumber", selectedIdx)
			}
		}

		return {
			content,
			selectIndex,
			index,
			questions,
			currentVote,
			isValidAnswer
		}
	},
});
