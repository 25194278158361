<template ref="template">
	<ion-list-header class="ion-no-padding"
		>Inserisci la password per continuare</ion-list-header
	>
	<ion-item>
		<ion-label position="stacked">Password</ion-label>
		<ion-input
			type="password"
			v-model="password"
			placeholder="Inserisci la password"
		></ion-input>
	</ion-item>

	<div class="ion-text-center">
		<ion-button @click="submitPassword()" shape="round" class="mt-20"
			>{{t("Confirm and continue")}}</ion-button
		>
	</div>
</template>


<script lang="ts">
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import {
	IonButton,
	IonInput,
	IonLabel,
	IonItem,
	IonListHeader,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
		IonButton,
		IonLabel,
		IonItem,
		IonInput,
		IonListHeader,
	},
	name: "PasswordModal",
	data() {
		return {
			password: "",
		};
	},
	methods: {
		async handleChange(eventData: any) {
			this.password = eventData.detail.value;
		},

		submitPassword() {
			this.$emit("modal-submit", this.password);
		},
	},
	emits: ["modal-submit"],
	setup(){
		const  { t } = useI18nAffidaty()
		return {
			t
		}
	}
});
</script>

<style scoped>
</style>
