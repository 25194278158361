<template>
	<ion-header class="ion-no-border">
		<ion-toolbar color="trasparent">
			<div class="d-flex ion-align-items-top ion-justify-content-between ion-margin mt-10 mr-10 mb-0">
          <ion-text color="dark">
            <h2 @click="showInfo()" class="mt-0 d-flex ion-align-items-center">
				{{ title }}
			</h2>
          </ion-text>
				<ion-icon v-on:click="handleDidDismiss(true)"
					class="close-icon"
					color="dark"
					:icon="icons.closeCircleOutline"
				></ion-icon>
			</div>
			
		</ion-toolbar>
	</ion-header>
	<ion-content class="ion-padding">
		<div v-if="info" class="info-modal">
			<small><i>{{info}}</i></small>
		</div>
		<template v-if="type === 'anonymous'">
			<AnonymousModal></AnonymousModal>
		</template>
		<template v-if="type === 'ballotInformation'">
			<BallotInformationModal :ballot="data"></BallotInformationModal>
		</template>
		<template v-if="type === 'question'">
			<QuestionModal
				@onSubmitAnswer="handleDidSubmit"
				:question="data.question"
				:answers="data.answers"
			></QuestionModal>
		</template>
		<template v-if="type === 'language'">
			<LanguageModal
				@modal-submit="handleDidSubmit"
				:languages="data"
			></LanguageModal>
		</template>
		<template v-if="type === 'password'">
			<PasswordModal @modal-submit="handleDidSubmit"></PasswordModal>
		</template>
		<template v-if="type === 'text'">
			<TextContainer :text="data"></TextContainer>
		</template>
		<template v-if="type === 'info'">
			<InfoModal :informations="data"></InfoModal>
		</template>
	</ion-content>
</template>

<script lang="ts">
import {
	IonContent,
	IonHeader,
	IonToolbar,
	IonIcon,
	IonText,
	modalController,
} from "@ionic/vue";
import { defineComponent, ref, toRefs } from "vue";
import { closeCircleOutline, informationCircle } from "ionicons/icons";
import AnonymousModal from "@/components/modals/AnonymousModal.vue";
import BallotInformationModal from "@/components/modals/BallotInformationModal.vue";
import QuestionModal from "@/components/modals/QuestionModal.vue";
import LanguageModal from "@/components/modals/LanguageModal.vue";
import PasswordModal from "@/components/modals/PasswordModal.vue";
import TextContainer from "@/components/modals/TextContainer.vue"
import InfoModal from "@/components/modals/InfoModal.vue"
const modalProps = {
	type: {
		type: String,
		default: null,
		required: true,
	},
	title: {
		type: String,
	},
	data: {
		type: Object,
	},
	info: {
		type: String,
		default: ""
	}
}
export default defineComponent({
	components: {
		IonContent,
		IonHeader,
		IonToolbar,
		IonIcon,
		IonText,
		AnonymousModal,
		BallotInformationModal,
		QuestionModal,
		LanguageModal,
		PasswordModal,
		TextContainer,
		InfoModal,
	},
	name: "Modal",
	data() {
		return {
			content: "",
		};
	},
	props: modalProps,
	methods: {
		handleDidDismiss(isCancelled: boolean) {
			modalController.dismiss({ type: this.type, event: "close" });
			this.$emit("modal-closed", { isCancelled });
		},

		handleDidSubmit(data: any) {
			modalController.dismiss({
				type: this.type,
				event: "submit",
				data: data,
			});
			this.$emit("modal-submit", { data });
		},
	},

	setup(props) {
		const { info } = toRefs(props)
		const showInfoModal = ref<boolean>(false)
		const showInfo = () => {
			showInfoModal.value = !showInfoModal.value 
		}
		return {
			info,
			showInfoModal,
			showInfo,
			icons: {closeCircleOutline, informationCircle},
		};
	},
	emits: ["modal-closed", "modal-submit"],
});
</script>

