
import {
	IonContent,
	IonPage,
	IonFooter,
	IonButtons,
	IonButton,
	IonIcon,
	IonItem,
	IonToolbar,
	IonModal
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { closeCircle, informationCircleOutline, ellipse, ellipseOutline, eyeOff, shieldCheckmark, informationCircle } from "ionicons/icons";
import Modal from "../components/modals/index.vue";
import ProgressBarComponent from "@/components/layout/ProgressBarComponent.vue";
import useModal from "@/composables/ModalApi";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { votationIsFinished, votationIsStarted } from '@/utils/utils'
import { Ballot } from "@/models/types";

export default defineComponent({
	name: "VoteIntro",
	components: {
		IonContent,
		IonPage,
		IonFooter,
		IonButtons,
		IonButton,
		IonIcon,
		IonItem,
		IonToolbar,
		ProgressBarComponent,
		IonModal,
		Modal
	},
	setup() {
		const store = useStore()
		const { t, getLangWithoutRegion } = useI18nAffidaty()
		const currentBallot = computed<Ballot>(() => store.getters.currentBallot)
		const { scopedModalInfo, openModal, handleModalClosed } = useModal('scoped')
		const decriptionMaxLength = 180;
		const errorbrandLogo = computed(() => store.getters['images/errorBrandLogoImage'])
		const errorBannerImage = computed(() => store.getters['images/errorBannerImage'])
		const getDisabledButtonLabel = (): string => {
			let label = ""
			if (!votationIsStarted(currentBallot.value)){
				label = t("Votation not starded")
			} else if(votationIsFinished(currentBallot.value)){
				label = t("Finished")
			} else if (currentBallot.value.isVoted){
				label = t("Vote already submitted")
			}
			
			return label
		}

		return {
			icons: { closeCircle, informationCircleOutline, ellipse, ellipseOutline, eyeOff, shieldCheckmark, informationCircle },
			currentBallot,
			openModal,
			modalInfo: scopedModalInfo,
			handleModalClosed,
			lang: getLangWithoutRegion(),
			votationIsFinished,
			votationIsStarted,
			t,
			getDisabledButtonLabel,
			decriptionMaxLength,
			errorbrandLogo,
			errorBannerImage
		}
	},
});
