
import { computed, defineComponent, toRefs } from 'vue';
import { checkmarkCircleOutline, personOutline, playCircleOutline } from "ionicons/icons";
import {
    IonButton,
	IonLabel,
    IonIcon, 
    IonRow,
    IonCol,
    IonText
} from "@ionic/vue";
import {getThumbnailFromYoutube} from '../../utils/youTubeUtils'
import { useStore } from 'vuex';
 
const videoListItemProps = {
    id: {
        type: String,
        required: true
    },
    selected: {
        type: Object,
        required: true,
        default: false
    },
    title: {
        type: String,
        required: true,
        default: "",
    },
    url: {
        type: String,
        required: true,
        default: "",
    },
    buttonLabel: {
        type: String,
        required: true
    },
}
export default defineComponent({
    components: {
        IonCol,
        //IonButton,
        IonIcon, 
        //IonLabel,
        IonRow,
        IonText
    },
    emits: ["onVoteItem", "onOpenVideo"],
    props: videoListItemProps,
    setup(props, {emit}) {
        const {
            title, 
            url, 
            buttonLabel, 
            id,
            selected
        } = toRefs(props)
        const store = useStore()
		const errorbrandLogo = computed(() => store.getters['images/errorBrandLogoImage'])


        const openVideo = () => {
           emit("onOpenVideo", {url: url.value, title: title.value})
        }

        const onVote = () => {
           emit("onVoteItem", id!.value)
        }

        return {
            getThumbnailFromYoutube,
            onVote,
            title, 
            url, 
            buttonLabel,
            openVideo,
            icons: { 
                personOutline,
                checkmarkCircleOutline,
                playCircleOutline
            },
            isSelected: computed(() => (selected.value as any) == id!.value),
            errorbrandLogo
        }
    },
})
