
import { computed, defineComponent, toRefs } from 'vue';
import {
	IonLabel,
    IonItem,
    IonText
} from "@ionic/vue";
import { useStore } from 'vuex';
 
const listItemImageProps = {
    id: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true,
        default: "",
    },
    subtitle: {
        type: String,
        required: true,
        default: ""
    },
    url: {
        type: String,
        required: true,
        default: "",
    }
}
export default defineComponent({
    components: {
        IonLabel,
        IonItem,
        IonText
    },
    emits: ["onClickItem"],
    props: listItemImageProps,
    setup(props, {emit}) {
        const {
            title, 
            url,
            subtitle,
            id
        } = toRefs(props)
        const store = useStore()
        const errorBannerImage = computed(() => store.getters['images/errorBannerImage'])
        const onClickItem = () => {
           emit("onClickItem", {url: url.value, title: title.value})
        }

        return {
            onClickItem,
            title, 
            url,
            subtitle,
            id,
            errorBannerImage
        }
    },
})
