<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div
				class="head-image"
				:style="`background-image: url(${currentBallot.logo}), url(${errorBannerImage})`"
			>
				<ion-toolbar class="transparent">
					<ion-buttons slot="end">
						<router-link to="/dashboard" replace
							><ion-icon color="light"
								:icon="icons.closeCircle"
								slot="start"
							></ion-icon
						></router-link>
					</ion-buttons>
				</ion-toolbar>
			</div>
			<div class="logo-content ion-margin-start">
				<div
					class="logo-content2"
					:style="`background-image: url(${currentBallot.icon}), url(${errorbrandLogo})`"
				></div>
			</div>
			<div class="title-section">
				<div class="title-detail">
					<ion-item lines="none">
						<ion-icon class="fs-30 ion-margin-end" color="primary" v-if="currentBallot.anonymous" @click="openModal('anonymous', t('Information', 2))" :icon="icons.eyeOff" slot="start">
						</ion-icon>
						<ion-icon class="fs-30 ion-margin-end" color="primary" @click="openModal('ballotInformation', t('Security'), currentBallot)" :icon="icons.shieldCheckmark" slot="start">
						</ion-icon>
						<ion-icon v-if="currentBallot.informations && currentBallot.informations.length" class="fs-30 mr-0" color="primary" @click="openModal('info', t('Information', 2), currentBallot.informations)" :icon="icons.informationCircle" slot="start">
						</ion-icon>
					</ion-item>
				</div>
				<h4 class=" ion-margin mb-5">
					<ion-text color="primary" >
						{{ currentBallot.title[lang] }}
					</ion-text>
				</h4>
				<p class="ion-margin mb-10 mt-10">
					{{ currentBallot.description[lang]? currentBallot.description[lang].truncateTextEndOfWord(decriptionMaxLength, '...') : ""}}&nbsp;
					<ion-text
						color="primary"
						@click="openModal('text', t('Vote'), currentBallot.description[lang])"  
						v-if="currentBallot.description[lang] && currentBallot.description[lang].length > decriptionMaxLength">
						{{t('More').toLowerCase()}}
					</ion-text> 
				</p>

				<div class="ion-margin">
					<ion-item lines="none" class="ion-no-padding ion-no-margin mh-20">
						<ion-label class="mb--10" slot="start">
							<h4><ion-text class="color-gray"><b>{{t("Vote start").toUpperCase()}}</b></ion-text></h4>
							<h2><b>{{$filters.formatDate(currentBallot.start)}}</b></h2>
						</ion-label>

						<ion-label slot="end" class="mr--10 oo-h ion-text-right mb--3">
							<h4><ion-text class="color-gray"><b>{{t("Vote end").toUpperCase()}}</b></ion-text></h4>
							<h2><b>{{$filters.formatDate(currentBallot.end)}}</b></h2>
						</ion-label>
					</ion-item>

					<div class="lineatemp">
						<ProgressBarComponent
							:start="currentBallot.start"
							:end="currentBallot.end"
						></ProgressBarComponent>
					</div>
				</div>
			</div>
		</ion-content>
		<ion-modal
			:is-open="modalInfo.show"
			css-class="custom"
			@didDismiss="handleModalClosed"
		>
			<Modal
				:type="modalInfo.type"
				:title="modalInfo.title"
				:data="modalInfo.data"
				@modal-closed="handleModalClosed"
				@modal-submit="handleModalSubmit"
			></Modal>
		</ion-modal>
		<ion-footer>
			<ion-button
				v-if="!currentBallot.isVoted && !votationIsFinished(currentBallot) && votationIsStarted(currentBallot)"
				router-link="question-list"
				shape="round"
        size="large"
				expand="full"
				class="ionic-text-center ion-margin"
				color="primary"
			>
				{{t("Vote")}}
			</ion-button>
			<ion-button
				v-else
				shape="round"
        size="large"
				expand="full"
				class="ionic-text-center ion-margin"
				color="primary"
				disabled
			>
				{{getDisabledButtonLabel()}}
			</ion-button>
		</ion-footer>
	</ion-page>
</template>

<script lang="ts">
import {
	IonContent,
	IonPage,
	IonFooter,
	IonButtons,
	IonButton,
	IonIcon,
	IonItem,
	IonToolbar,
	IonModal
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { closeCircle, informationCircleOutline, ellipse, ellipseOutline, eyeOff, shieldCheckmark, informationCircle } from "ionicons/icons";
import Modal from "../components/modals/index.vue";
import ProgressBarComponent from "@/components/layout/ProgressBarComponent.vue";
import useModal from "@/composables/ModalApi";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { votationIsFinished, votationIsStarted } from '@/utils/utils'
import { Ballot } from "@/models/types";

export default defineComponent({
	name: "VoteIntro",
	components: {
		IonContent,
		IonPage,
		IonFooter,
		IonButtons,
		IonButton,
		IonIcon,
		IonItem,
		IonToolbar,
		ProgressBarComponent,
		IonModal,
		Modal
	},
	setup() {
		const store = useStore()
		const { t, getLangWithoutRegion } = useI18nAffidaty()
		const currentBallot = computed<Ballot>(() => store.getters.currentBallot)
		const { scopedModalInfo, openModal, handleModalClosed } = useModal('scoped')
		const decriptionMaxLength = 180;
		const errorbrandLogo = computed(() => store.getters['images/errorBrandLogoImage'])
		const errorBannerImage = computed(() => store.getters['images/errorBannerImage'])
		const getDisabledButtonLabel = (): string => {
			let label = ""
			if (!votationIsStarted(currentBallot.value)){
				label = t("Votation not starded")
			} else if(votationIsFinished(currentBallot.value)){
				label = t("Finished")
			} else if (currentBallot.value.isVoted){
				label = t("Vote already submitted")
			}
			
			return label
		}

		return {
			icons: { closeCircle, informationCircleOutline, ellipse, ellipseOutline, eyeOff, shieldCheckmark, informationCircle },
			currentBallot,
			openModal,
			modalInfo: scopedModalInfo,
			handleModalClosed,
			lang: getLangWithoutRegion(),
			votationIsFinished,
			votationIsStarted,
			t,
			getDisabledButtonLabel,
			decriptionMaxLength,
			errorbrandLogo,
			errorBannerImage
		}
	},
});
</script>

<style scoped>
.head-image {
	background-size: cover;
	background-position: center;
	/*height: 40%;*/
  height: 280px;
}

.languages {
	display: flex;
	align-content: center;
	justify-content: center;
}
.languages div {
	margin: 5px;
}
.languages img {
	height: 30px;
	width: auto;
	border-radius: 100%;
}
.languages span {
	display: block;
}
ion-button .flag {
	height: 20px;
	width: auto;
	margin-left: 20px;
	border-radius: 100%;
}
.title-section {
	background: var(--ion-background-color);
	border-radius: 20px 20px 0px 0px;
	margin-top: -65px;
	padding-top: 55px;
	position: relative;
	z-index: 1;
	box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.05);
}
.title-detail {
	position: absolute;
	top: 5px;
	right: 20px;
	text-align: right;
}
</style>
