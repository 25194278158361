
import {
  IonText
} from "@ionic/vue";
import { defineComponent, toRefs } from "vue";
const textContainerProps = {
  text: {
    type: String,
    default: ""
  }
}
export default defineComponent({
	components: {
    IonText
	},
  props: textContainerProps,
  setup(props) {
    const { text } = toRefs(props)
    return {
      text
    }
  }
});
