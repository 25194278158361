<template>
	<ion-page>
		<ion-content :fullscreen="true">

			<Header :anonymous="currentBallot.anonymous" :displayLogo="false" :displayLine="false" @onAnonymousClick="openModal('anonymous')" />

			<div class="title-section ion-padding">
				<h2 class="ion-text-center page-title">{{t("Vote summary")}}</h2>
			</div>

			<ion-card
				class="custom"
				v-for="(question, index) in currentBallot.questions"
				:key="question.id"
			>
				<ion-grid>
					<ion-row>
						<ion-col size="2">
							<ion-fab-button
								:class="`stepper-card__fab stepper-card__fab--active ion-margin-horizontal ${isValidAnswer(question, currentVote)? '' : 'swipe-card--danger'}`"
								color="light"
								>{{ index + 1 }}</ion-fab-button
							>
						</ion-col>
						<ion-col size="10">
							<b>{{ question.question }}</b>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col>
							<ion-item
								v-for="answer in getQuestionAnswers(
									question.id
								)"
								:key="answer.option"
								lines="none"
								class="ion-no-padding"
								style="padding-left: 14px"
							>
								<p class="ion-no-margin">
									{{ answer.option }}
								</p>

								<ion-icon
									:icon="icons.ellipse"
									size="small"
									slot="start"
									class="riepilogo-card__bullet"
								></ion-icon>
							</ion-item>

							<!-- <div class="ion-justify-content-center" style="display: flex">
                <ion-button
                  size="small"
                  v-on:click="openModal('question', question)"
                  >Modifica</ion-button
                >
              </div> -->
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-card>

			<ion-modal
				:is-open="modalInfo.show"
				css-class="custom"
				@didDismiss="handleModalClosed"
			>
				<Modal
					:type="modalInfo.type"
					:title="modalInfo.title"
					:data="modalInfo.data"
					@modal-closed="handleModalClosed"
					@modal-submit="handleModalSubmit"
				></Modal>
			</ion-modal>
			<ion-toast
				:position="'top'"
				:is-open="toastInfo.show"
				:message="toastInfo.message"
				:duration="toastInfo.duration"
				@didDismiss="hideToast"
			></ion-toast>
		</ion-content>

		<ion-fab vertical="bottom" horizontal="end" slot="fixed">
			<ion-button
				class="button-round"
				color="primary"
				v-on:click="resetVotation"
				>{{t("Cancel", 2)}}</ion-button>
			<ion-button
				class="button-round"
				color="primary"
				@click="sendVote()"
				>{{t("Confirm")}}</ion-button
			>
		</ion-fab>

		<ion-footer class="ion-no-border" transparent>
			<ion-toolbar> </ion-toolbar>
		</ion-footer>

		<ion-loading
			:is-open="isLoading"
			cssClass=""
			:message="`${t('Loading')}...`"
		>
		</ion-loading>
	</ion-page>
</template>

<script lang="ts">
import {
	IonContent,
	IonPage,
	IonCard,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	IonItem,
	IonFabButton,
	IonIcon,
	IonFab,
	IonFooter,
	IonToolbar,
	IonModal,
	IonToast,
	IonLoading
} from "@ionic/vue";
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { ellipse } from "ionicons/icons";
import Modal from "@/components/modals/index.vue";
import HttpRest from "@/services/http";
import {
	indexFromData,
	isValidAnswer
} from "../utils/utils";
import { useRouter } from "vue-router";
import { RootStore } from "@/store";
import { Ballot, TransactionAccountData } from "@/models/types";
import Tx from '@/utils/transaction'
import { Account, binConversions, RSAKey, BlindRSA } from '@affidaty/t2-lib'
import Header from '@/components/layout/Header.vue'
import useModal from "@/composables/ModalApi";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import Log from '../utils/log'
export default defineComponent({
	name: "VoteConfirm",
	components: {
		IonContent,
		IonPage,
		IonCard,
		IonGrid,
		IonCol,
		IonRow,
		IonButton,
		IonItem,
		IonFabButton,
		IonIcon,
		IonFab,
		IonFooter,
		IonToolbar,
		IonModal,
		Modal,
		IonToast,
		IonLoading,
		Header
	},
	props: {},
	methods: {
		getQuestionAnswers(idQuestion: string) {
			const answers = this.currentVote.find((item: any) => {
				return item.id === idQuestion;
			});
			const question = this.currentBallot.questions.find(
				(item: any) => {
					return item.id === idQuestion;
				}
			);

			const result = [];

			for (const answer of answers.values) {
				if (question){
					const option = question.options.find((item: any) => {
						return item.id === answer;
					});
					result.push({
						type: option? option.question : "",
						option: option? option.value : "",
					});
				}
			}

			return result;
		},

		resetVotation() {
			this.$router.replace("vote-intro");
		}
	},

	unmounted() {
		// Reset votation on unmount event
		this.$store.dispatch("resetVotation");
	},
	setup() {
		const store = useStore<RootStore>()
		const { t } = useI18nAffidaty()
		const router = useRouter()
		const isLoading = ref(false);
		const currentVote = computed(() => store.getters.currentVote)
		const currentBallot = computed<Ballot>(() => store.getters.currentBallot)
		const showLoader = (state: boolean) => isLoading.value = state;
		const { scopedModalInfo, openModal, handleModalClosed, handleSubmitModal } = useModal('scoped')


		const toastInfo = reactive<{
			show: boolean;
			message: string;
			duration: number;
		}>({
			show: false,
			message: "",
			duration: 2000,
		});

		const showToast = (message: string, duration: number) => {
			toastInfo.show = true;
			toastInfo.message = message;
			toastInfo.duration = duration || 2000;
		};

		const hideToast = () => toastInfo.show = false;


		const handleError = (message: string, timeout = 3000) => {
			showToast(message, timeout);
			showLoader(false)
		}

		const sendVoteTransaction = (pollingStationIndex: number, accountData: TransactionAccountData, unblindedSignature?: Buffer) => {
			let errorMessage = ""
			const args = currentBallot.value.anonymous? { token: unblindedSignature, answers: currentVote.value } : { answers: currentVote.value }
			const smartContract = currentBallot.value.anonymous? process.env.VUE_APP_SC_VOTE_BLIND : process.env.VUE_APP_SC_BASIC_NO_BLIND
			Log.log(smartContract)
			return Tx.createTx(accountData, "add_vote", args, smartContract || "")
			.then(tx => {
				showLoader(false);
				return Tx.signTx(tx, store.state.authInRef)
			})
			.then(signedTransaction => {
				errorMessage = "Errore durante la sottomissione del voto"
				showLoader(true);
				let url
				let params
				if (currentBallot.value.anonymous){
					url = "/vot8/submitVote"
					params = {tx: signedTransaction}
				} else {
					url = "/vot8/submitUnblindVote"
					params = {tx: signedTransaction, ballotHash: currentBallot.value.hash, accountId: store.state.account.accountId, pollingStation: pollingStationIndex}
				}
				return HttpRest.post(url, params)
			})
			.then((response: any) => {
				/* console.log(response); */

				// TODO: Salvo la mappa di idBallot e ticket (con status per futuri sviluppi)
				const votes = JSON.parse(localStorage.getItem('votes') || "[]");
				votes.push({idBallot: currentBallot.value.polling_stations[pollingStationIndex].id, status: response.data.data.status, ticket: response.data.data.ticket})

				localStorage.setItem('votes', JSON.stringify(votes));

				// Update ballots informations
				store.dispatch("getBallots");

				// When votation process is completed
				localStorage.removeItem('voteInformations');
				router.replace("vote-thanks" /*  {hash: response.hash} */);
			})
			.catch(() => {
				handleError(errorMessage)
			})
		}

		const anonymousVote = (account: Account, accountData: TransactionAccountData) => {
			const virtualAccount = new Account()
			let ballotPubKey: RSAKey
			let pollingStationIndex: number
			let blindIdV: Uint8Array
			let blindingFactor: any
			let errorMessage = "Errore nella generazione delle chiavi anonime"
			showLoader(true);
			virtualAccount.generate().then(() => {
				errorMessage = "Errore nella codifica dell'account id"
				return indexFromData(binConversions.base58ToBuffer(virtualAccount.accountId), currentBallot.value.polling_stations.length)
			})
			.then((pollingIndex: any) => {
				errorMessage = "Errore nella codifica dell'account id"
				pollingStationIndex = pollingIndex
				// Ho l'indice del ballot :D
				ballotPubKey = new RSAKey("public")
				return ballotPubKey.setSPKI(
					new Uint8Array(binConversions.base58ToArrayBuffer(currentBallot.value.polling_stations[pollingStationIndex].pk_rsa.publicKey))
				)
				// return ballotPubKey.setSPKI(
				// 	new Uint8Array(binConversions.base58ToArrayBuffer("2TuPVgMCHJy5atawrsADEzjP7MCVbyyCA89UW6Wvjp9HrAxt7suBLByvEq6MyT8LA8BX9Xr2e7DzohH3LmBvZjDt2QH1GLZY6RgUuy1UZrPeY42h2ktE6jRPLMGgZPbyd8VkNptrH83JdShSVauHkaGRsNQdUWfSa6HGBKZRY52fjjmPoR1wmBN9dgrZKaACQ9CZCh4XAeqRJUJDSgNLPW39BYjMao9aV6XVAHtPHy8e5pvTd4GSwarLszCZEdycymtXGJpJjbPBreyBgQndp8xAD2pPDKh5pvrAQxzs9iwYMZxkbBeQEY6P53bMNALVFofCBTGdKbWyH3Xzt5PsemuSKHUMHYWJQ9JUexu4kj12UNA5XsKUM83CpiDgtMkArGDa46eLigFmnWM2NC"))
				// )
			})
			.then(() => {

				//console.log("ballot pubK: ", currentBallot.value.polling_stations[pollingStationIndex].pk_rsa.publicKey)
				//console.log("ballot salt: ", currentBallot.value.polling_stations[pollingStationIndex].salt)
				//TODO: verificare se da rimuovere
				const ballotSalt = binConversions.base58ToBuffer(currentBallot.value.polling_stations[pollingStationIndex].salt);
				localStorage.setItem('voteInformations', JSON.stringify({idV: virtualAccount.accountId, ballotSalt, ballotPubKey, keypair: virtualAccount.keyPair}));
				
				return BlindRSA.getFactor(ballotPubKey)
			})
			.then(blindingFactorRes => {
				blindingFactor = blindingFactorRes
				//blindingFactor= "QfR63Uuo1UnvvsegPqKKTzhbYeMLw3Vapv1hKvADypLNt8dJ4skPVa62vPg54S6x1SVSteK3cdJyd32SitNKoUbvyvHT3Lmw8yL4PgN2qDhnoN7c8m3v8Rrpmw8StHuw8Ey9zsCS8uEQJBFEi18A7Abh7VC4LhL19GVzfVyGkx3u7Ha"
				/* console.log('blinding..') */
				const idVBin = new Uint8Array(binConversions.stringToArrayBuffer(virtualAccount.accountId));
				//const idVBin = new Uint8Array(binConversions.stringToArrayBuffer("QmepaPMYy6fBtNfJk4NaAv1MiBbK2Cx7BF1YL5kESsLAX6"));
				console.log("idVBin: ", binConversions.arrayBufferToString(idVBin.buffer))
				return BlindRSA.applyBlinding(idVBin, blindingFactor, ballotPubKey);
			})
			.then(applyBlindingResult => {
				blindIdV = applyBlindingResult
				const args = {
					idV: binConversions.arrayBufferToBase58(blindIdV),
					pollingStation: pollingStationIndex,
					ballotHash: currentBallot.value.hash,
					accountId: account.accountId
				}
				
				console.log("blindIdv: ", binConversions.arrayBufferToBase58(blindIdV))
				console.log("blindIdv: ", binConversions.arrayBufferToBase58(applyBlindingResult))
				console.log("acccountId user: ", account.accountId)
				return Tx.createTx(accountData, "", args)
			})
			.then(transaction => {
				showLoader(false);
				return Tx.signTx(transaction, store.state.authInRef)
			})
			.then(signedTransaction => {
				errorMessage = "Errore nel recupero del token anonimo"
				showLoader(true);
				return HttpRest.post("/blindsig/get-token", {tx: signedTransaction})
			})
			.then(blindSignature => {
				console.log("blindSignature", blindSignature)
				// const token = "V7ELypT5s1nQWLcn3p3EQLJscCtNY44egpuaMwUEFQLr6NnjArhNewPG8PPQppub7s4yW1rwTJUmWsbERNm7b8fS3d3PgcYBnVx4JbhfAzMSSN6GR7xw2Qr9RF1vkYoceUbdVowqCwBnpTLJabmuuZtN14edrtLoQsNtjNrdxZBcw4jLhkPoWtrVReD1dZrrt11XaNPDMq7f6TfRFweDW3y1dqfEuBV2cfe3GgKDwv787D1X8JbRSCURdxwrVtB4YYRaisFJQHgtP7gLZnmpWLqZvm6XaE7u6MejuLFcG5rWAEFKZWcajCmxVoohvc1Y7cToqLFjtTZaUewAgEgCiq8ShrZGu"
				// ↓↓↓this↓↓↓ is ballot token
				return BlindRSA.removeBlinding(new Uint8Array(binConversions.base58ToArrayBuffer(blindSignature.data.ballotToken)),blindingFactor,ballotPubKey)
			})
			.then(unblindedSignature => {
				console.log("unblindedSignature: ", binConversions.arrayBufferToBase58(unblindedSignature))
				accountData.accountId = currentBallot.value.polling_stations[pollingStationIndex].id

				sendVoteTransaction(pollingStationIndex, accountData, binConversions.arrayBufferToBuffer(unblindedSignature.buffer))
			}).catch((err) => {
				console.log(err)
				handleError(errorMessage)
			})
			.finally(() => showLoader(false))
		}



		const sendVote = () => {
			/* console.log('submitting..'); */
			// Get user informations from local storage
			const account = store.state.account
			const accountData: TransactionAccountData ={
				accountId: "",
				publicKey: store.state.account.keyPair!.publicKey
			}

			if (currentBallot.value.anonymous){
				anonymousVote(account, accountData)
			} else {
				indexFromData(binConversions.base58ToBuffer(account.accountId), currentBallot.value.polling_stations.length)
				.then((pollingStationIndex: any) => {
					accountData.accountId = currentBallot.value.polling_stations[pollingStationIndex].id
					sendVoteTransaction(pollingStationIndex, accountData)
				})
				.catch(() => handleError("Errore nella codifica dell'account id"))
				.finally(() => showLoader(false))
			}
		}



		return {
			icons: { ellipse },
			modalInfo: scopedModalInfo,
			openModal,
			handleModalClosed,
			toastInfo,
			showToast,
			hideToast,
			isLoading,
			showLoader,
			sendVote,
			handleError,
			currentVote,
			currentBallot,
			handleSubmitModal,
			t,
			isValidAnswer
		};
	},
});
</script>

<style scoped>
.title-section .page-title,
.title-section {
	margin: 0px;
	padding: 0px;
}
.title-section {
	padding-bottom: 0px;
}
ion-toolbar {
	--background: transparent;
	--ion-color-base: transparent !important;
}
ion-footer {
	background: rgb(2, 0, 36);
	background: linear-gradient(
		0deg,
		var(--ion-color-secondary-contrast) 0%,
		transparent
	);
}
.logo-content-centred {
	margin-top: 30px;
}
</style>
