<template ref="template">
	<div class="ion-padding-horizontal">
		<ion-text color="dark">
			<h3 class="mt-0">{{t("Certification of the regularity of the vote")}}</h3>
		</ion-text>
	</div>

	<ion-card>
		<ion-card-content>
			<ion-list class="mb-20">
				<ion-item lines="none" style="margin-left: -15px">
					<ion-thumbnail slot="start">
						<img :onerror="`this.src='${errorbrandLogo}'`" :src="`${ballot.icon}`" />
					</ion-thumbnail>
					<ion-label>
            <h2>{{t("Vote title")}}:</h2>
            <h3>{{ ballot.title[lang] }}</h3>
          </ion-label>
				</ion-item>
			</ion-list>

			<div class="well mt-5 mb-10">
				{{ hash }}
			</div>
			<ion-button v-on:click="checkHash" shape="round" color="primary" expand="block"
				>{{t("Verify ballot hash")}}</ion-button
			>
			<template v-if="hashChecked">
				<div v-if="hashValid" class="d-flex justify-content-center padding-top-m">
					<div class="margin-top-s">
						<img class="text-center--img_text" src="/assets/icon/check-circle-fill.png" />
						<p style="color: green"><b>{{t("Hash valido")}}!</b></p>
					</div>
				</div>
				<div v-if="!hashValid" class="d-flex justify-content-center padding-top-m">
					<div class="margin-top-s">
						<img class="text-center--img_text" src="/assets/icon/close-circle-sharp.png" />
						<p style="color: darkred"><b>{{t("Hash non valido")}}!</b></p>
					</div>
				</div>
			</template>
		</ion-card-content>
	</ion-card>

	<ion-card>
		<ion-card-content>
			<ion-text color="dark">
				<h5><b>{{t("What happens in case of invalid hash")}}?</b></h5>
			</ion-text>
			<p>
				{{t("Voting data may have been tampereted")}}.
			</p>
		</ion-card-content>
	</ion-card>
</template>


<script lang="ts">
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { Ballot } from "@/models/types";
import { Subtle, Utils } from "@affidaty/t2-lib";
import {
/* 	IonRow, 
	IonCol,  */
	IonButton,
	IonText,
	IonThumbnail,
	IonLabel,
	IonList,
	IonCardContent,
	IonCard,
	IonItem
} from "@ionic/vue";
import { computed, defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { getBallotDataToBeVerified } from '../../utils/utils'

export default defineComponent({
	components: {
/* 		IonRow,
		IonCol, */
		IonButton,
		IonText,
		IonThumbnail,
		IonLabel,
		IonList,
		IonCardContent,
		IonCard,
		IonItem
	},
	name: "BallotInformationModal",
	data() {
		return {
			hashChecked: false,
			hashValid: false,
			hash: "",
		};
	},
	props: {
		ballot: {
			type: Object as PropType<Ballot>,
			required: true,
		},
	},

	mounted() {
		this.hash = this.ballot.hash;
	},
	methods: {
		checkHash() {
			// Clone ballot object 
			const filteredBallot = {...getBallotDataToBeVerified(this.ballot)};
                      
			// hash the message
			Subtle.digest('SHA-256', Utils.objectToBytes(filteredBallot)).then((hashBuffer: ArrayBuffer) => {
				// convert buffer to byte array
				const hashArray = Array.from(new Uint8Array(hashBuffer));            
				// convert bytes to hex string         
				const ballotHashString = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
				this.hashChecked = true;
				this.hashValid = this.ballot.hash === ballotHashString;	
			})         

			// ObjectTransporter.hash(ballot).then((ballotHash: any) => {
			// 	this.hashChecked = true;
			// 	this.hashValid = this.hash === ballotHash;
			// });
		},
	},
	setup(){
		const { t, getLangWithoutRegion } = useI18nAffidaty()
		const store = useStore()
		const errorbrandLogo = computed(() => store.getters['images/errorBrandLogoImage'])
		return {
			lang: getLangWithoutRegion(),
			t,
			errorbrandLogo
		}
	}
});
</script>

<style scoped>
p.text-small {
	font-size: 0.66em;
}

.well {
	background: #f5f5f5;
	border-radius: 20px;
	padding: 20px;
}
</style>
