<template>
    <ion-row class="empty-state">
        <ion-col class="empty-state__img" size="3" size-sm="2" size-lg="2" size-xl="2">
            <img 
                onerror="this.src='/assets/img/icon-empty-alt.png'"
                :src="imgUrl" 
            />
        </ion-col>
        <ion-col size="12" class="ion-text-center">
            <slot></slot>
        </ion-col>
    </ion-row>
</template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { IonRow, IonCol} from '@ionic/vue'

const notFounComponentProps = {
    imgUrl: {
        type: String,
        default: ""
    }   
}
export default defineComponent({
    components: {
        IonRow,
        IonCol
    },
    props: notFounComponentProps,
    setup(props) {
        const { imgUrl } = toRefs(props)

        return {
            imgUrl
        }
    },
})
</script>
<style scoped>
/* component empty-state */
.empty-state {display: flex; justify-content: center; width: 100%; }
.empty-state__img {padding: 50px 0 20px !important; }
.empty-state__btn {--padding-start: 40px; --padding-end: 40px;}
</style>
