
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { Ballot } from "@/models/types";
import { Subtle, Utils } from "@affidaty/t2-lib";
import {
/* 	IonRow, 
	IonCol,  */
	IonButton,
	IonText,
	IonThumbnail,
	IonLabel,
	IonList,
	IonCardContent,
	IonCard,
	IonItem
} from "@ionic/vue";
import { computed, defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { getBallotDataToBeVerified } from '../../utils/utils'

export default defineComponent({
	components: {
/* 		IonRow,
		IonCol, */
		IonButton,
		IonText,
		IonThumbnail,
		IonLabel,
		IonList,
		IonCardContent,
		IonCard,
		IonItem
	},
	name: "BallotInformationModal",
	data() {
		return {
			hashChecked: false,
			hashValid: false,
			hash: "",
		};
	},
	props: {
		ballot: {
			type: Object as PropType<Ballot>,
			required: true,
		},
	},

	mounted() {
		this.hash = this.ballot.hash;
	},
	methods: {
		checkHash() {
			// Clone ballot object 
			const filteredBallot = {...getBallotDataToBeVerified(this.ballot)};
                      
			// hash the message
			Subtle.digest('SHA-256', Utils.objectToBytes(filteredBallot)).then((hashBuffer: ArrayBuffer) => {
				// convert buffer to byte array
				const hashArray = Array.from(new Uint8Array(hashBuffer));            
				// convert bytes to hex string         
				const ballotHashString = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
				this.hashChecked = true;
				this.hashValid = this.ballot.hash === ballotHashString;	
			})         

			// ObjectTransporter.hash(ballot).then((ballotHash: any) => {
			// 	this.hashChecked = true;
			// 	this.hashValid = this.hash === ballotHash;
			// });
		},
	},
	setup(){
		const { t, getLangWithoutRegion } = useI18nAffidaty()
		const store = useStore()
		const errorbrandLogo = computed(() => store.getters['images/errorBrandLogoImage'])
		return {
			lang: getLangWithoutRegion(),
			t,
			errorbrandLogo
		}
	}
});
