import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import moment from "moment";

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { store } from './store'

import '@/utils/string'
/* I18N */
import useI18nAffidaty from './composables/I18nAffidatyApi';

/*  MIDDLEWARE GUARD SECTION  */
router.beforeEach((to, from, next) => {
	// if application isn't initialized redirect to home
	if (!store.getters.initialized) {
		store.state.initialized = true;
		next({ path: '/', replace: true });
	} else next();
})

/*  ------------------------  */

/**
 * Auth in dynamic import based on env
 */
 const authinCss = document.createElement('link')
 authinCss.setAttribute('rel', `stylesheet`)
 authinCss.setAttribute('href', `${process.env.VUE_APP_AUTHIN_CDN || ""}/affidaty-auth-in.css`)
 document.head.appendChild(authinCss)
 const authinEsm = document.createElement('script')
 authinEsm.setAttribute('type', `module`)
 authinEsm.setAttribute('data-stencil', "")
 authinEsm.setAttribute('data-resources-url', "/build/")
 authinEsm.setAttribute('data-stencil-namespace', "affidaty-auth-in")
 authinEsm.setAttribute('src', `${process.env.VUE_APP_AUTHIN_CDN || ""}/affidaty-auth-in.esm.js`)
 document.head.appendChild(authinEsm)
 const authinJs = document.createElement('script')
 authinJs.setAttribute('data-stencil', "")
 authinJs.setAttribute('nomodule', "")
 authinJs.setAttribute('src', `${process.env.VUE_APP_AUTHIN_CDN || ""}/affidaty-auth-in.js`)
 document.head.appendChild(authinJs)

const app = createApp(App)
	.use(store)
	.use(useI18nAffidaty().i18n)
	.use(IonicVue)
	.use(router);

/*  GLOBAL FILTER SECTION  */

// Date filter
app.config.globalProperties.$filters = {
	// Format date 
	formatDate(value: number, seconds = false) {
		return (seconds) ? moment(value * 1000).format('DD/MM/YYYY hh:mm') : moment(value * 1000).format('DD/MM/YYYY')
	}
}

/*  ---------------------  */

router.isReady().then(() => {
	app.mount('#app');
});