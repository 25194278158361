import { Module } from "vuex";
import { RootStore } from "..";

export interface UiModule {
  language: string;
  isLoading: boolean;
}

export const ui: Module<UiModule, RootStore> = {
  namespaced: true,
  state: {
    language: 'en-US',
    isLoading: false
  },
  getters: {
    lang: state => state.language,
    isLoading: state => state.isLoading
  },
  actions: {
    checkLanguage: ({commit, state}) => {
      const lang = localStorage.getItem("language") || 'en-US'
      if (lang != state.language){
        commit("setLanguage", lang)
      }
    },
  },
  mutations: {
    setLanguage: (state, payload: string) => {
      localStorage.setItem("language", payload)
      state.language = payload
    },
    triggerLoader: (state, payload: boolean) => {
      state.isLoading = payload
    },
  }
}