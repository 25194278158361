<template>
    <div class="video-player__container">
        <ion-header class="ion-no-border">
            <ion-toolbar color="dark">
                <ion-title>{{ videoTitle }}</ion-title>
                <ion-icon
                    class="cursor-pointer close-icon"
                    slot="end"
                    @click="closeVideo"
                    color="light"
                    :icon="icons.closeCircleOutline"
                />
            </ion-toolbar>
        </ion-header>
        <iframe
            class="youtube-player"
            :src="url" 
            :title="videoTitle" 
            modestbranding="1"
            frameborder="0"
            :autoplay="autoPlay?1:0"
            fs="1"
            rel="0"
            controls="2"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen/>
    </div>
</template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import {
    IonHeader,
	IonTitle,
	IonToolbar,
	IonIcon
} from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons"
import { getYoutubeEmbedLink } from '../utils/youTubeUtils'
const youTubePlayerProps = {
    url: {
        type: String,
        required: true,
        default: ""
    },
    videoTitle: {
        type: String,
        required: true
    },
    autoPlay: {
        type: Boolean,
        default: false
    },
}
export default defineComponent({
    components: {
        IonHeader,
        IonTitle,
        IonToolbar,
        IonIcon
    },
    props: youTubePlayerProps,
    emits: ["onCloseVideo"],
    setup(props, {emit}) {
        const {url, videoTitle, autoPlay} = toRefs(props)
        const closeVideo = () => {
            emit("onCloseVideo")
        }
        return {    
            icons: { closeCircleOutline },
            url: getYoutubeEmbedLink(url.value),
            videoTitle,
            autoPlay,
            closeVideo,
        }
    }
})
</script>
<style scoped>
.video-player__container{position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index: 99991; background-color: #000; display: flex;align-items: center;flex-direction: column;}
.youtube-player{margin: auto;width: 100%;width: -webkit-fill-available;height: -webkit-fill-available;}
</style>
