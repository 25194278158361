<template>
  <div class="ion-padding-horizontal">
    <div v-for="(info, index) in informations" :key="index">
      <ion-text v-if="info.title" color="dark">
          <h3 class="mt-10 d-flex ion-align-items-center">
            {{ info.title[lang] }}
          </h3>
      </ion-text>
      <ion-text v-if="info.text" color="dark">
        <p>{{info.text[lang]}}</p>
      </ion-text>
      <a v-if="info.url" color="primary" :href="info.url[lang]" target="_blank">
        <ion-button class="ion-no-margin mt-5" size="small" shape="round">Link</ion-button>
      </a>
    </div>
  </div>
</template>


<script lang="ts">
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { BallotInformation } from "@/models/types";
import {
  IonText,
  IonButton
} from "@ionic/vue";
import { defineComponent, PropType, toRefs } from "vue";
const infoModalProps = {
  informations: {
    type: Object as PropType<BallotInformation[]>,
    default: []
  }
}
export default defineComponent({
	components: {
    IonText,
    IonButton
	},
  props: infoModalProps,
  setup(props) {
    const { getLangWithoutRegion } = useI18nAffidaty()
    const { informations } = toRefs(props)
    return {
      informations,
      lang: getLangWithoutRegion(),
    }
  }
});
</script>

<style scoped>

</style>
