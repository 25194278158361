
import { defineComponent } from "vue";
import { 
	homeOutline, 
	informationCircleOutline, 
	personOutline, 
	exitOutline,
	optionsOutline 
} from "ionicons/icons";
import { 
    IonFooter,
    IonIcon, 
    IonRow,
    IonCol ,
    IonLabel
} from "@ionic/vue";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";

export default defineComponent({
	name: "Footer",
	components: {
			IonFooter,
			IonIcon, 
			IonRow,
			IonCol ,
			IonLabel
	},
	setup() {
		const { t } = useI18nAffidaty()
		return {
			icons: { 
				homeOutline,
				informationCircleOutline,
				personOutline,
				exitOutline,
				optionsOutline
			},
			t
		}
	},
});
