
import { defineComponent, toRefs } from 'vue'
import { IonRow, IonCol} from '@ionic/vue'

const notFounComponentProps = {
    imgUrl: {
        type: String,
        default: ""
    }   
}
export default defineComponent({
    components: {
        IonRow,
        IonCol
    },
    props: notFounComponentProps,
    setup(props) {
        const { imgUrl } = toRefs(props)

        return {
            imgUrl
        }
    },
})
