import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import VoteIntro from '../views/VoteIntro.vue';
import VoteConfirm from '../views/VoteConfirm.vue';
import VoteThanks from '../views/VoteThanks.vue';
import QuestionList from '../views/QuestionList.vue';
import Dashboard from '../views/Dashboard.vue';
import NotFound from '../components/NotFound.vue';
import Logout from '../components/Logout.vue';
import Report from "@/views/Report.vue";
import { store } from '@/store';
import { isEmptyOrNull } from '@/utils/utils';
import useI18nAffidaty from '@/composables/I18nAffidatyApi';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/home',
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		meta: {requiresAuth: true}
	},
	{
		path: '/vote-intro',
		name: 'vote-intro',
		component: VoteIntro,
		meta: {requiresAuth: true}
	},
	{
		path: '/question-list',
		name: 'question-list',
		component: QuestionList,
		meta: {requiresAuth: true}
	},
	{
		path: '/vote-confirm',
		name: 'vote-confirm',
		component: VoteConfirm,
		meta: {requiresAuth: true}
	},
	{
		path: '/vote-thanks',
		name: 'vote-thanks',
		component: VoteThanks,
		meta: {requiresAuth: true}
	},
	{
		path: '/vote-report',
		name: 'vote-report',
		component: Report,
		meta: {requiresAuth: true}
	},
	{
		path: '/guide',
		name: 'guide',
		component: () => import('@/views/Guide.vue'),
		meta: {requiresAuth: true}
	},
	{
		path: '/settings',
		name: 'settings',
		component: () => import('@/views/Settings.vue'),
		meta: {requiresAuth: true}
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		meta: {requiresAuth: true}
	},
	{
		path: "/:catchAll(.*)",
		component: NotFound,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	//#region i18n
	const {loadLocaleMessages, defaultLocale} = useI18nAffidaty()
	store.dispatch("ui/checkLanguage")
	const lang = store.getters['ui/lang'] || defaultLocale
	if (lang){
		const i18nUrl = `${process.env.VUE_APP_I18N_BASE_URL}/${lang}.json`
		loadLocaleMessages(lang, i18nUrl)
	}
	//#endregion
	if (to.meta && to.meta.requiresAuth && isEmptyOrNull(store.state.userInformations)){
		next({path: "/"})
	} else {
		next()
	} 
})
export default router
