<template>
    <ion-item  @click="onClickItem" class="ion-no-padding d-flex ion-align-items-start">
      <div slot="start" class="votation-list__thumb">
        <img class="votation-list__thumb__img"  :onerror="`this.src='${errorBannerImage}'`" :src="url" :alt="title">
      </div>

        <ion-label class="pt-5 mr-50" style="white-space: normal">
            <h2>
              {{ title }}
            </h2>
            <h3 class="mb-10">
              <ion-text class="color-gray">
                {{ subtitle }}
              </ion-text>
            </h3>
          <slot slot="end"></slot>
        </ion-label>
        <slot name="status"></slot>
    </ion-item>
</template>
<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue';
import {
	IonLabel,
    IonItem,
    IonText
} from "@ionic/vue";
import { useStore } from 'vuex';
 
const listItemImageProps = {
    id: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true,
        default: "",
    },
    subtitle: {
        type: String,
        required: true,
        default: ""
    },
    url: {
        type: String,
        required: true,
        default: "",
    }
}
export default defineComponent({
    components: {
        IonLabel,
        IonItem,
        IonText
    },
    emits: ["onClickItem"],
    props: listItemImageProps,
    setup(props, {emit}) {
        const {
            title, 
            url,
            subtitle,
            id
        } = toRefs(props)
        const store = useStore()
        const errorBannerImage = computed(() => store.getters['images/errorBannerImage'])
        const onClickItem = () => {
           emit("onClickItem", {url: url.value, title: title.value})
        }

        return {
            onClickItem,
            title, 
            url,
            subtitle,
            id,
            errorBannerImage
        }
    },
})
</script>
<style scoped>

</style>
