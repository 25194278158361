<template>
	<ion-page>
		<Header/>
		<ion-content>
			<section
				class="ion-padding login-section ion-text-center h100 d-grid pt-0"
			>
				<div class="content">

					<h2 class="ion-text-center">
						<ion-text color="primary">
						<b>{{t("Votes list")}}</b>
						</ion-text>
					</h2>

					<!-- Votation list -->
					<ion-list class="votation-list" v-if="ballots && ballots.length">
						<template
							v-for="(ballot, index) in ballots"
							:key="ballot.owner.accountId"
						>
							<ListItemImage
								@onClickItem="onClickItemCallback(ballot, index)"
								:title="ballot.title[lang]"
								:subtitle="`${$filters.formatDate(ballot.start)} - ${$filters.formatDate(ballot.end)}`"
								:url="ballot.logo"
								:id="ballot.owner.accountId"
							>
								<template v-slot:default>
									<ion-button v-if="[t('Result', 2), t('Vote')].includes(getUserBallotStatus(ballot))" shape="round" color="primary">{{getUserBallotStatus(ballot)}}</ion-button>
									<ion-text v-if="getUserBallotStatus(ballot) === t('Voted')" color="primary">
										<p class="d-flex ion-align-items-center">
											<ion-icon class="fs-30 mr-5" :icon="icons.checkmarkCircle"></ion-icon>
											<b>{{getUserBallotStatus(ballot)}}</b>
										</p>
									</ion-text>
								</template>
								<template v-slot:status>
									<div v-if="votationIsStarted(ballot) && !votationIsFinished(ballot)" :class="`cr cr-top cr-right ${getBallotStatusClass(ballot)}`">{{getBallotStatus(ballot)}}</div>
								</template>
							</ListItemImage>

						</template>
					</ion-list>
					<!-- end Votation list -->
					<NotFound v-else>
						<p>{{t("No votations available")}}</p>
					</NotFound>
				</div>
			</section>
		</ion-content>
		<Footer/>
	</ion-page>
</template>

<script lang="ts">
import {
	IonContent,
	IonPage,
	IonList
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import Footer from "@/components/layout/Footer.vue";
import Header from '@/components/layout/Header.vue'
import { RootStore } from "@/store";
import { useRouter } from "vue-router";
import { Ballot } from "@/models/types";
import ListItemImage from '@/components/ListItemImage.vue'
import NotFound from '@/components/NotFound.vue'
import { getMomentDateFromInt, votationIsFinished, votationIsStarted } from "@/utils/utils";
import moment from "moment";
import { checkmarkCircle } from "ionicons/icons";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";

export default defineComponent({
	name: "dashboard",
	components: {
		IonContent,
		IonPage,
		IonList,
		Footer,
		Header,
		ListItemImage,
		NotFound
	},
	setup() {
		const store = useStore<RootStore>()
		const router = useRouter()
		const { t, getLangWithoutRegion } = useI18nAffidaty()
		const userInformations = JSON.parse(
			localStorage.getItem("userInformations") || "{}"
		);
		// Set User informations
		store.dispatch("setUserInformations", userInformations);
		// Reset current ballot if we arrive from intro
		store.dispatch("setCurrentBallot", {});
		// Get ballots list
		store.dispatch("getBallots");
		// Intialize the application
		store.dispatch("setInitialized")
		const ballots = computed<Ballot[]>(() => store.getters.ballots)

		const ballotIntro = (index: number) => {
			store.dispatch("setCurrentBallot", ballots.value[index]);
			router.push("vote-intro");
		}

		const ballotReport = (index: number) => {
			store.dispatch("setCurrentBallot", ballots.value[index]);
			router.replace("vote-report");
		}

		const ballotHasResults = (ballot: any) => {
			return Object.prototype.hasOwnProperty.call(ballot, "results");
		}

		const getBallotStatus = (ballot: Ballot): string => {
			//CHIUSO
			if (!votationIsStarted(ballot)){
				return t("Soon")
			} 
			//TERMINATO
			else if(votationIsFinished(ballot)){
				return t("Finished")
			} 
			//IN CORSO
			else {
				return t("In progress")
			}
		}
		const getUserBallotStatus = (ballot: Ballot): string => {
			const dateStart = getMomentDateFromInt(ballot.start)	
			const dateEnd = getMomentDateFromInt(ballot.end)	

			//CHIUSO
			if (moment().isBefore(dateStart)){
				return ""
			} 
			//TERMINATO
			if(ballotHasResults(ballot)){
				return t("Result", 2 as any)
			}
			if (moment().isAfter(dateEnd) && !ballot.isVoted){
				return ""
			}
			return !ballot.isVoted ? t("Vote") : t("Voted")
			
		}

		const getBallotStatusClass = (ballot: Ballot) => {
			const status = getBallotStatus(ballot)
			switch(status){
				case t("Soon"):
					return "cr-red"
				case t("Finished"):
					return "cr-blue"
				case t("In progress"):
					return "cr-green"
			}
		}

		const onClickItemCallback = (ballot: Ballot, index: number) => {
			if(ballotHasResults(ballot)){
				return ballotReport(index)
			} else {
				return ballotIntro(index)
			}
		}
		
		return {
      icons: {checkmarkCircle},
			votationIsFinished,
			votationIsStarted,
			ballotIntro,
			ballotReport,
			ballotHasResults,
			ballots,
			onClickItemCallback,
			getBallotStatus,
			getUserBallotStatus,
			getBallotStatusClass,
			t,
			lang: getLangWithoutRegion()
		};
	},
});
</script>

<style scoped>

</style>