
import { defineComponent, toRefs } from 'vue'
import {
    IonHeader,
	IonTitle,
	IonToolbar,
	IonIcon
} from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons"
import { getYoutubeEmbedLink } from '../utils/youTubeUtils'
const youTubePlayerProps = {
    url: {
        type: String,
        required: true,
        default: ""
    },
    videoTitle: {
        type: String,
        required: true
    },
    autoPlay: {
        type: Boolean,
        default: false
    },
}
export default defineComponent({
    components: {
        IonHeader,
        IonTitle,
        IonToolbar,
        IonIcon
    },
    props: youTubePlayerProps,
    emits: ["onCloseVideo"],
    setup(props, {emit}) {
        const {url, videoTitle, autoPlay} = toRefs(props)
        const closeVideo = () => {
            emit("onCloseVideo")
        }
        return {    
            icons: { closeCircleOutline },
            url: getYoutubeEmbedLink(url.value),
            videoTitle,
            autoPlay,
            closeVideo,
        }
    }
})
