
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { IonRow, IonCol } from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
	components: {
		IonRow,
		IonCol,
	},
	name: "AnonymousModal",
	setup(){
		const store = useStore()
		const brandLogo = computed(() => store.getters['images/brandLogo'])
		const errorbrandLogo = computed(() => store.getters['images/errorBrandLogoImage'])
		const { t } = useI18nAffidaty()
		return {
			t,
			brandLogo,
			errorbrandLogo
		}
	} 
});
