
import {
  IonContent,
  IonPage,
  IonLabel,
  IonItem,
  IonAvatar,
  IonList,
  IonCardContent,
  IonCard
  } from '@ionic/vue';
import {computed, defineComponent} from 'vue';
import { useStore } from "vuex";
import Footer from "@/components/layout/Footer.vue";
import Header from '@/components/layout/Header.vue'
import { RootStore } from '@/store';
import { Ballot, BallotResultLocal } from '@/models/types';
import useI18nAffidaty from '@/composables/I18nAffidatyApi';

export default defineComponent({
  name: 'Report',
  components: {
    IonContent,
    IonPage,
    IonLabel,
    IonItem,
    IonAvatar,
    IonList,
    IonCardContent,
    IonCard,
    Footer,
    Header
  },
  setup() {
    const store = useStore<RootStore>()
    const { t } = useI18nAffidaty()
    const currentBallot = computed<Ballot>(() => store.getters.currentBallot)
    store.dispatch("getCurrentBallotResults", {
			ballotId: currentBallot.value.hash,
		})
    const currentBallotResults = computed<BallotResultLocal>(() => store.getters.currentBallotResults)

    const getWinnerOption = (questionId: string, optionId: string): boolean => {
      const question = currentBallotResults.value.result.find(question => question.id === questionId)
      if (question){
        const maxValue = Math.max(...question.result.map(function(o: any) { return o.votes; }))
        const option = question.result.find(option => option.id === optionId)
        if (option){
          // Check if is the winner option
          return option.votes >= maxValue
        } 
        return false
      } 
      return false
    }
    return {
      currentBallot,
      currentBallotResults,
      getWinnerOption,
      t
    }
  }
});
