<template>
  <ion-page>
    <Header/>
    <ion-content class="ion-padding" :fullscreen="true">

      <div class="title-section ion-padding-horizontal">
        <h3 class="mb-0">
          <ion-text color="dark">
            {{t("Vote results")}}:
          </ion-text>
        </h3>
        <h3 class="mt-5"><ion-text color="primary">{{currentBallotResults.title}}</ion-text> </h3>
      </div>

      <ion-card class="mb-30" v-for="question in currentBallotResults.result" :key="question.id">
        <ion-card-content>
          <ion-list class="page-report-list">
            <div class="title-section ion-padding">
              <h2 class="">
                <ion-text color="dark">{{question.title}}</ion-text>

              </h2>
            </div>
            <ion-item lines="none" class="page-report-item page-report__rank-empty">
              <ion-label class="page-report-item__text">
                <h3><ion-text color="dark">Risposte</ion-text></h3>
              </ion-label>
              <div slot="end" class="text-center">
                <h3><ion-text color="dark">Voti</ion-text></h3>

              </div>
            </ion-item>

            <!-- item -->
            <ion-item v-for="(option, index) in question.result" :key="option.id" class="page-report-item">
              <!-- forzare il colore verde al pallino: page-report__rank-active -->
              <ion-avatar 
                :class="`page-report__rank ${getWinnerOption(question.id, option.id)? 'page-report__rank-active' : ''}`" slot="start">
                {{index + 1}}
              </ion-avatar>
              <ion-label>
                <p><ion-text color="dark">{{option.title}}</ion-text></p>
              </ion-label>
              <div slot="end" class="text-center">

                {{option.votes}}
              </div>
            </ion-item>





          </ion-list>
        </ion-card-content>
      </ion-card>
      <!-- end blocco ballot-->


    </ion-content>
    <Footer></Footer>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonLabel,
  IonItem,
  IonAvatar,
  IonList,
  IonCardContent,
  IonCard
  } from '@ionic/vue';
import {computed, defineComponent} from 'vue';
import { useStore } from "vuex";
import Footer from "@/components/layout/Footer.vue";
import Header from '@/components/layout/Header.vue'
import { RootStore } from '@/store';
import { Ballot, BallotResultLocal } from '@/models/types';
import useI18nAffidaty from '@/composables/I18nAffidatyApi';

export default defineComponent({
  name: 'Report',
  components: {
    IonContent,
    IonPage,
    IonLabel,
    IonItem,
    IonAvatar,
    IonList,
    IonCardContent,
    IonCard,
    Footer,
    Header
  },
  setup() {
    const store = useStore<RootStore>()
    const { t } = useI18nAffidaty()
    const currentBallot = computed<Ballot>(() => store.getters.currentBallot)
    store.dispatch("getCurrentBallotResults", {
			ballotId: currentBallot.value.hash,
		})
    const currentBallotResults = computed<BallotResultLocal>(() => store.getters.currentBallotResults)

    const getWinnerOption = (questionId: string, optionId: string): boolean => {
      const question = currentBallotResults.value.result.find(question => question.id === questionId)
      if (question){
        const maxValue = Math.max(...question.result.map(function(o: any) { return o.votes; }))
        const option = question.result.find(option => option.id === optionId)
        if (option){
          // Check if is the winner option
          return option.votes >= maxValue
        } 
        return false
      } 
      return false
    }
    return {
      currentBallot,
      currentBallotResults,
      getWinnerOption,
      t
    }
  }
});
</script>

<style scoped>

</style>
