<template ref="template">
	<ion-row class="d-flex justify-content-center">
		<img :onerror="`this.src='${errorbrandLogo}'`" :src="brandLogo" class="logo" />
	</ion-row>
	<!-- <p class="ion-text-center">Vot8</p> -->
	<div class="title-section">
		<h2 class="ion-text-center page-title">{{t("Anonymous vote")}}</h2>
	</div>
	<p class="ion-margin">
		{{t("This vote uses the anonymous VOTE system guaranteed by Affidaty s.p.A and based on Blockchain technology")}}
	</p>
	<ion-row >
		<ion-col>
			<div class="powered ion-margin">
				Powered by <img src="assets/img/affidaty.png" class="logo-footer" />
			</div>
		</ion-col>
	</ion-row>
</template>


<script lang="ts">
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { IonRow, IonCol } from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
	components: {
		IonRow,
		IonCol,
	},
	name: "AnonymousModal",
	setup(){
		const store = useStore()
		const brandLogo = computed(() => store.getters['images/brandLogo'])
		const errorbrandLogo = computed(() => store.getters['images/errorBrandLogoImage'])
		const { t } = useI18nAffidaty()
		return {
			t,
			brandLogo,
			errorbrandLogo
		}
	} 
});
</script>

<style scoped>
</style>
