
import { IonProgressBar } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
		IonProgressBar,
	},
	name: "ProgressBarComponent",
	props: {
		start: { type: Number, required: true },
		end: { type: Number, required: true },
	},
	setup(props: any) {
		const evaluateProgressBar = () => {
			const startDate = new Date(props.start * 1000).getTime(); // Jan 1, 2015
			const endDate = new Date(props.end * 1000).getTime(); // August 24, 2015
			const today = new Date().getTime(); // April 23, 2015

			// If today is greater than end date return complete progress
			if (endDate < today) {
				return 1;
			}
			// TODO: Truncate if necessary
			return (today - startDate) / (endDate - startDate);
		};

		return {
			evaluateProgressBar,
		};
	},
});
