interface String {
  toCapitalizedAll(): string;
  /**
   * This method allow to to truncate text at firts white space before the character specified in textMaxLength property
   * @param textMaxLength the length after string would be truncated
   * @param textAfterTruncate string to be displayed after text truncation
   * @returns string
   */
  truncateTextEndOfWord(textMaxLength: number, textAfterTruncate: string): string;
}

String.prototype.toCapitalizedAll = function() {
  return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};  


String.prototype.truncateTextEndOfWord = function(textMaxLength: number, textAfterTruncate: string){
  if (this.length > textMaxLength){
      const truncatedString = this.substr(0, textMaxLength)
      if (truncatedString.lastIndexOf(" ") > 0 ){
          return `${truncatedString.substr(0, Math.min(truncatedString.length, truncatedString.lastIndexOf(" ")))}${textAfterTruncate}`
      } else {
          return `${truncatedString.substr(0, truncatedString.length)}${textAfterTruncate}`
      }
  }
  return this.valueOf()
}