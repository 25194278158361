
import { ITxObject } from '@affidaty/t2-lib';
import { defineComponent, onMounted, onUnmounted, PropType, ref, toRefs } from 'vue';
import { TermsConditions } from '../models/types'
const authInProps = {
    visible:{
        type: Boolean
    },
    anonymousMessage: {
        type: String,
    },
    loggedMessage: {
        type: String,
    },
    unauthorizedMessage: {
        type: String
    },
    lang: {
        type: String
    },
    apiendpoint: {
        type: String
    },
    application: {
        type: String
    },
    apikey: {
        type: String
    },
    onAuthInSuccess: {
        type: Function as PropType<() => void>
    },
    appid: {
        type: String
    },
    writepermission: {
        type: Object
    },
    buttonLabel: {
        type: String
    },
    dev: {
        type: Boolean
    },
    env: {
        type: String
    }
}
export default defineComponent({
  name: 'AuthInComponent',
  props: authInProps,
  emits: ["onAuthInReady"],
  setup(prop, {emit}) {
    const authIn = ref(null)
    const {
        lang, 
        anonymousMessage, 
        loggedMessage, 
        unauthorizedMessage, 
        apiendpoint, 
        application, 
        apikey, 
        visible, 
        appid, 
        writepermission, 
        buttonLabel, 
        onAuthInSuccess, 
        dev, 
        env
    } = toRefs(prop)

    const getUserInfo = (): Promise<any> => {
        return (authIn.value as any).getUserInformations()
    }
    const signTransaction = (objectTxToSign: ITxObject, termsConditionsUrls: TermsConditions[] = []): Promise<ITxObject> => {
        //console.log("sign tx", JSON.stringify(objectTxToSign))
        return (authIn.value as any).signTransaction(objectTxToSign, termsConditionsUrls)
    }

    const logout = (): Promise<boolean> => {
        //console.log("logout")
        return (authIn.value as any).logout()
    }

    onMounted(() => {
        (authIn.value as any).style = visible.value? "z-index: 10000;margin: 0px auto 0px auto;" : "position: absolute;left: -99999px;";
        (authIn.value as any).addEventListener('affidaty:auth-in:ready', (event: any) => {
            emit('onAuthInReady', event.target);
            
            (authIn.value as any).authSuccess = onAuthInSuccess?.value;
            (authIn.value as any).addEventListener('affidaty:auth-in:success', (data: any) => {
                const authSuccess = (document.querySelector("affidaty-sign") as any).authSuccess
                if(data.detail.event === "logged" && authSuccess) {
                    authSuccess()
                }
            })
        });
    })

    onUnmounted(() => {
        console.log(`%c unmounted: ${document.location.pathname}`, "color:#7e959c;font-weight:bold")
    })

    return {
        authIn,
        visible,
        getUserInfo,
        signTransaction,
        lang,
        anonymousMessage,
        loggedMessage,
        unauthorizedMessage, 
        apiendpoint, 
        application, 
        apikey, 
        appid, 
        writepermission, 
        buttonLabel,
        onAuthInSuccess,
        logout,
        dev,
        env
    }
  }
});
