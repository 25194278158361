
import { computed, defineComponent, toRefs } from 'vue'
import {
	IonHeader,
} from "@ionic/vue";
import useI18nAffidaty from '@/composables/I18nAffidatyApi';
import { informationCircleOutline } from "ionicons/icons";
import { useStore } from 'vuex';

const pageHeaderProps = {
    anonymous:{
        type: Boolean,
        default: false
    },
    logoUrl: {
        type: String
    },
    displayLine: {
        type: Boolean,
        default: true
    },
    displayLogo: {
        type: Boolean,
        default: true
    }
}
export default defineComponent({
    components: {
        IonHeader
    },
    props: pageHeaderProps,
    emits: ["onAnonymousClick"],
    setup(props, {emit}) {
        const { t } = useI18nAffidaty()
        const store = useStore()
        const brandLogo = computed(() => store.getters['images/brandLogo'])
        const errorbrandLogo = computed(() => store.getters['images/errorBrandLogoImage'])
        const { 
            anonymous, 
            logoUrl, 
            displayLine, 
            displayLogo 
        } = toRefs(props)
        
        const onAnonymousClick = () => {
            emit("onAnonymousClick")
        }
        return {
            anonymous,
            logoUrl,
            displayLine,
            onAnonymousClick,
            icons: {
                informationCircleOutline
            },
            t,
            displayLogo,
            brandLogo,
            errorbrandLogo
        }
    },
})
