<template>
	<ion-footer class="ion-padding">
		<ion-row class="menu-voteadm">
			<ion-col size="3">
				<router-link to="/dashboard" replace>
					<ion-icon :icon="icons.homeOutline"></ion-icon>
				</router-link>
				<ion-label>{{t("Ballot", 2)}}</ion-label>
			</ion-col>
			<ion-col size="3">
				<router-link to="/guide" replace>
					<ion-icon :icon="icons.informationCircleOutline"></ion-icon>
					<ion-label>Info</ion-label>
				</router-link>
			</ion-col>
			<ion-col size="3">
				<router-link to="/settings" replace>
					<ion-icon :icon="icons.optionsOutline"></ion-icon>
					<ion-label>{{t("Settings")}}</ion-label>
				</router-link>
			</ion-col>
			<ion-col size="3">
				<router-link to="/logout" replace>
					<ion-icon :icon="icons.exitOutline"></ion-icon>
					<ion-label>Logout</ion-label>
				</router-link>
			</ion-col>
		</ion-row>
	</ion-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { 
	homeOutline, 
	informationCircleOutline, 
	personOutline, 
	exitOutline,
	optionsOutline 
} from "ionicons/icons";
import { 
    IonFooter,
    IonIcon, 
    IonRow,
    IonCol ,
    IonLabel
} from "@ionic/vue";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";

export default defineComponent({
	name: "Footer",
	components: {
			IonFooter,
			IonIcon, 
			IonRow,
			IonCol ,
			IonLabel
	},
	setup() {
		const { t } = useI18nAffidaty()
		return {
			icons: { 
				homeOutline,
				informationCircleOutline,
				personOutline,
				exitOutline,
				optionsOutline
			},
			t
		}
	},
});
</script>
