
import {
	IonPage,
	IonRow,
	IonCol,
	IonText
} from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import * as allIcons from "ionicons/icons";
import { useStore } from "vuex"
import { useRouter } from "vue-router";
import { Account, binConversions, ECDSAKeyPair } from '@affidaty/t2-lib'
import AuthInComponent from '@/components/AuthInComponent.vue'
import Header from '@/components/layout/Header.vue'
import useI18nAffidaty from '../composables/I18nAffidatyApi'

export default defineComponent({
	name: "Home",
	components: {
		IonText,
		IonPage,
		IonRow,
		IonCol,
		Header,
		AuthInComponent
	},
	setup() {
		const store = useStore()
		const authInComponent = ref()
		const router = useRouter()
		const { t } = useI18nAffidaty()

		const setLoggedUser = () => {
			const user = JSON.parse(localStorage.getItem("userInformations") || "");
			const account = new Account();
			const keyPair = new ECDSAKeyPair();
			keyPair.publicKey.setSPKI(binConversions.base58ToBuffer(user.publicKey)).then(() => {
				return account.setKeyPair(keyPair)
			}).then(() => {
				store.dispatch('setAccount', account);
				store.dispatch('setUserInformations', user);
				router.replace({name: "dashboard"});
			})
		}

		const authReady = () => {
			authInComponent.value.getUserInfo().then((infos: any) => {
				if(infos.logged) {
					setLoggedUser()
				}
			})
		}



		return {
			t,
			icons: allIcons,
			showLogin: false,
			setLoggedUser,
			env: process.env.VUE_APP_ENVIRONMENT,
			authinApplication: process.env.VUE_APP_AUTHIN_APPLICATION,
			authinApiendpoint: process.env.VUE_APP_AUTHIN_APIENDPOINT,
			authinApikey: process.env.VUE_APP_AUTHIN_APIKEY,
			authReady,
			language: computed(() => store.getters['ui/lang']),
			authInComponent
		};
	},
});
