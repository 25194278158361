
import {
  IonContent,
  IonPage,
  IonIcon,
  IonAvatar,
  IonCol,
  IonRow,
  IonButton,
  IonGrid,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { checkmarkCircle, homeOutline, fileTrayFullOutline } from "ionicons/icons";
import Footer from "@/components/layout/Footer.vue";
import Header from '@/components/layout/Header.vue'
import useI18nAffidaty from "@/composables/I18nAffidatyApi";

export default defineComponent({
  name: "vote-thanks",
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonAvatar,
    IonCol,
    IonRow,
    IonButton,
    IonGrid,
    Footer,
    Header
  },
  methods: {},
  setup() {
    const { t } = useI18nAffidaty()
    return {
      icons: { checkmarkCircle, homeOutline, fileTrayFullOutline },
      t
    };
  },
});
