import { reactive } from "vue";

interface Modal {
    show: boolean;
    data?: any;
    title: string;
    type: string;
    info: string;
}

type ModalContext = 'global' | 'scoped'

export const modalInfo = reactive<Modal>({
    show: false,
    type: "",
    data: null,
    title: "",
    info: ""
});

export default function useModal(context: ModalContext) {
    const init: Modal = {
        show: false,
        type: "",
        data: null,
        title: "",
        info: ""
    }
    Object.freeze(init)
    const scope: ModalContext = context
    
    const scopedModalInfo = reactive<Modal>({...init})


    const openModal = (type: string, title?: string, data?: any, info = "") => {
        if (scope === 'global'){
            modalInfo.type = type
            modalInfo.show = true
            modalInfo.title = title || ""
            modalInfo.info =  info
            modalInfo.data = data || null
        } else {
            scopedModalInfo.type = type
            scopedModalInfo.show = true
            scopedModalInfo.title = title || ""
            scopedModalInfo.info = info
            scopedModalInfo.data = data || null
        }
    }

    const handleModalClosed = () => {
        if (scope === 'global'){
            modalInfo.show = init.show
            modalInfo.type = init.type
            modalInfo.title = init.title
            modalInfo.info = init.info
            modalInfo.data = init.data
        } else {
            scopedModalInfo.show = init.show
            scopedModalInfo.type = init.type
            scopedModalInfo.title = init.title
            scopedModalInfo.info = init.info
            scopedModalInfo.data = init.data
        }
    };

    const handleSubmitModal = (cb: any) => {
        if (cb){
            cb()
        }
        handleModalClosed()
    }

    return {
        scopedModalInfo,
        globalModalInfo: () => modalInfo, 
        openModal,
        handleModalClosed,
        handleSubmitModal
    }

}
