
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import { BallotInformation } from "@/models/types";
import {
  IonText,
  IonButton
} from "@ionic/vue";
import { defineComponent, PropType, toRefs } from "vue";
const infoModalProps = {
  informations: {
    type: Object as PropType<BallotInformation[]>,
    default: []
  }
}
export default defineComponent({
	components: {
    IonText,
    IonButton
	},
  props: infoModalProps,
  setup(props) {
    const { getLangWithoutRegion } = useI18nAffidaty()
    const { informations } = toRefs(props)
    return {
      informations,
      lang: getLangWithoutRegion(),
    }
  }
});
