<template>
	<template v-for="(question, innerIndex) in questions" :key="question.id">
		<ion-fab-button
			class="stepper-card__fab ion-margin-horizontal "
			color="light"
			@click="selectIndex(innerIndex)"
			:class="{ 
				'stepper-card__fab--active': innerIndex === index,
				'stepper-card__fab--danger' : !isValidAnswer && innerIndex === index
			}"
			>{{ innerIndex + 1 }}</ion-fab-button
		>
	</template>
</template>

<script lang="ts">
import { IonFabButton } from "@ionic/vue";
import { computed, defineComponent, toRefs } from "vue";
import { useStore } from "vuex";

const slideMenuProps = {
	questions: {
		type: Object,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	isValidAnswer: {
		type: Boolean,
		required: true,
		default: true
	}
}
export default defineComponent({
	components: {
		IonFabButton,
	},
	name: "SlideMenuComponent",
	emit: ["onSelectQuestionNumber"],
	props: slideMenuProps,
	setup(props, {emit}) {
		const { questions, index, isValidAnswer } = toRefs(props)
		const content =  ""
		const store = useStore()
		const currentVote = computed(() => store.getters.currentVote)
		
		const selectIndex = (selectedIdx: number) => {
			if (index && selectedIdx != index.value){
				emit("onSelectQuestionNumber", selectedIdx)
			}
		}

		return {
			content,
			selectIndex,
			index,
			questions,
			currentVote,
			isValidAnswer
		}
	},
});
</script>

<style scoped>
</style>
