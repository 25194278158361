
import useI18nAffidaty from "@/composables/I18nAffidatyApi";
import {
	IonButton,
	IonInput,
	IonLabel,
	IonItem,
	IonListHeader,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
		IonButton,
		IonLabel,
		IonItem,
		IonInput,
		IonListHeader,
	},
	name: "PasswordModal",
	data() {
		return {
			password: "",
		};
	},
	methods: {
		async handleChange(eventData: any) {
			this.password = eventData.detail.value;
		},

		submitPassword() {
			this.$emit("modal-submit", this.password);
		},
	},
	emits: ["modal-submit"],
	setup(){
		const  { t } = useI18nAffidaty()
		return {
			t
		}
	}
});
