export const getYoutubeId = (url: string) => {
    const youtubeUrlMatch = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
    return youtubeUrlMatch? youtubeUrlMatch.pop() : ""
}

export const getThumbnailFromYoutube = (url: string) => {
    const videoId = getYoutubeId(url)
    return videoId? `https://img.youtube.com/vi/${videoId}/0.jpg` : ""
}

export const getYoutubeEmbedLink = (url: string) =>{
    const videoId = getYoutubeId(url)
    return videoId? `https://www.youtube.com/embed/${videoId}` : ""
}