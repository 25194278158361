<template>
	<span></span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
	name: "Logout",
	props: {},
	setup() {
		const store = useStore()
		// Clear user informations
		localStorage.removeItem("userInformations")
		store.dispatch("setUserInformations", {});
		store.dispatch("setAccount", {});
		location.href = "/";
	}
});
</script>
