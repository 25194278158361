
import { Question } from "@/models/types";
import { RootStore } from "@/store";
import {
	IonList,
	IonRadioGroup,
	IonItem,
	IonRadio,
	IonLabel,
	IonCheckbox,
	IonButton,
	IonIcon,
	IonText,
	alertController
} from "@ionic/vue";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import { useStore } from "vuex";
import VideoListItem from '@/components/modals/VideoListItem.vue';
import { arrowForwardOutline } from "ionicons/icons";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";

const questionModalProps = {
	question: {
		type: Object as PropType<Question>,
		required: true,
		default: {}
	},
	answers: {
		type: Object as PropType<any[]>,
		required: true,
		default: []
	}
}
export default defineComponent({
	components: {
		IonList,
		IonRadioGroup,
		IonItem,
		IonRadio,
		IonLabel,
		IonCheckbox,
		IonButton,
		IonIcon,
		VideoListItem,
		IonText
	},
	name: "QuestionModal",
	props: questionModalProps,
	setup(props, {emit}) {
		const store = useStore<RootStore>()
		const { t } = useI18nAffidaty()
		const { question, answers } = toRefs(props)

		const isMultiple = () => {
			return !(
				question.value.rules.min === 1 && question.value.rules.max == 1
			)
		}
		const answer = ref({
			id: question.value.id,
			values: isMultiple()? [...answers.value] : answers.value.toString(),
		})



		const isMultipleRadioValue = () => {
			return (
				question.value.rules.min === 1 && question.value.rules.max > 1
			)
		}

		const isChecked = (optionId: string) => answer.value.values.indexOf(optionId) >= 0

		const showVideoPlayer = (value: {url: string; title: string}) => {
			store.dispatch("showVideoPlayer", value)
		}

		const handleChoice = (selected: any) => {
			if (!isMultiple()) {
				answer.value = {...answer.value, values: selected}
			} else {
				const index = answer.value.values.indexOf(selected);
				if (index !== -1) {
					(answer.value.values as any[]).splice(index, 1);
					answer.value = {...answer.value};
				} else {
					answer.value = {...answer.value, values: [...answer.value.values, selected]};
				}
			}
		}
		
		const onVideoSelection = (value: string) => {
			handleChoice(value)
		}

		const confirmVote = () => {
			if (!isMultiple()){
				answer.value = {...answer.value, values: [...answer.value.values]}
			}
			store.dispatch("setCurrentVote", answer.value);
			emit("onSubmitAnswer", answer.value);
		}
		
		const showAlert = async (min: number, max?: number) => {
			let message = ""
			if(min && max) {
				message = t("Message min max answer vote", {min, max})
			} else {
				message = t('Message min answer vote', {min, answerLabel: t("Answer", (min === 1? 1 : 2) as any)})
			}
			

			const alert = await alertController
				.create({
				cssClass: 'my-custom-class',
				header: t('Attention'),
				subHeader: '',
				message: message ,
				buttons: [
					{
						text: t('Cancel'),
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: t('Confirm'),
						role: 'confirm',
						handler: () => {
							confirmVote();
						},
					},
				],
				});
				await alert.present();

				await alert.onDidDismiss();
				//console.log('onDidDismiss resolved with role', role);
		}

		const handleChange = (eventData: any) => {
			const selected = eventData.detail.value;
			handleChoice(selected)
		}
		
		const submitAnswers = () => {
			// check if the answer is correct otherwise alert user for confirm
			if (question) {

				// Singola o Radio
				if((!isMultiple() || isMultipleRadioValue()) && answer.value.values.length === 0) {
					showAlert(question.value.rules.min);
				} else if(isMultiple() && (answer.value.values.length < question.value.rules.min || answer.value.values.length > question.value.rules.max)) {
					showAlert(question.value.rules.min, question.value.rules.max);
				} else {
					confirmVote();
				}

			}
		}


		return {
			question,	
			answer,
			isMultiple,
			isMultipleRadioValue,
			currentVote: computed(() => store.getters.currentVote),
			showVideoPlayer,
			handleChoice,
			onVideoSelection,
			icons: { 
				arrowForwardOutline
			},
			t,
			confirmVote,
			submitAnswers,
			handleChange,
			alert,
			isChecked
		};
	},
	emits: ["onSubmitAnswer"],
});
