import { Module } from "vuex";
import { RootStore } from "..";

export interface BrandModule {
  brandLogo: string;
  brandDesktopBackgroud: string;
  errorBrandLogoImage: string;
  errorDesktopBackgroud: string;
  errorBanerImage: string;
}

export const images: Module<BrandModule, RootStore> = {
  namespaced: true,
  state: {
    brandLogo: "https://dev.cdn.affidaty.net/images/vot8/logo-customer.png",
    brandDesktopBackgroud: "https://dev.cdn.affidaty.net/images/vot8/bg-org_1920_1080.jpg",
    errorBrandLogoImage: "/assets/img/logo-org_400_400.png",
    errorDesktopBackgroud: "/assets/img/bg-org_1920_1080.jpg",
    errorBanerImage: "/assets/img/bg-vote_1000_600.jpg"
  },
  getters: {
    brandLogo: state => state.brandLogo,
    brandDesktopBackgroud:  state => state.brandDesktopBackgroud,
    errorBrandLogoImage: state => state.errorBrandLogoImage,
    errorDesktopBackgroud: state => state.errorDesktopBackgroud,
    errorBanerImage: state => state.errorBanerImage
  }
}